import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../logo.png";
import { Link } from "react-router-dom";
import "./Navbar.css";

function BasicExample() {
  return (
    <Navbar expand="lg" className="navbar">
      <Container>
        <Navbar.Brand href="/">
          <img
            src={logo}
            alt="logo"
            className="d-flex align-items-center"
            style={{ height: "3vh" }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav ms-auto">
            <Nav.Link
              as={Link}
              to="/"
              className="text-decoration-none text-light"
            >
              HOME
            </Nav.Link>
            <Nav.Link as={Link} to="/services">
              SOLUTIONS
            </Nav.Link>
            <Nav.Link as={Link} to="/partners">
              PARTNERS
            </Nav.Link>
            <Nav.Link as={Link} to="/howItWorks">
              HOW IT WORKS
            </Nav.Link>
            <Nav.Link as={Link} to="/about">
              ABOUT
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicExample;
