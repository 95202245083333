import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Services.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import serviceData from './Services.json';

function Services() {
  const [service] = useState(serviceData);

  return (
    <div>
      <section
        className="d-flex flex-column align-items-center justify-content-center" // Add my-4 for vertical margin
        style={{ background: "#252e44" }}
      >
      {/* TITLE */}
        <div className="d-flex justify-content-center align-items-center text-light mt-5">
          <h1 className="fs-1">
            Our <span style={{ color: "#92c8ff" }}>Services</span>
          </h1>
        </div>
        {/* CARDS */}
        <Row
          className="service-cardRow g-5"
        >
          {service.map((service, i) => (
            <Col
              key={i}
              xs={12} // cards per row on extra small screens
              md={6} // cards per row on medium screens
              lg={4} // cards per row on large screens
              className="d-flex justify-content-center"
            >
              <Card
                className="service-card border-light text-light d-flex flex-column"
              >
                <Card.Body className="d-flex flex-column justify-content-start align-items-center">
                  <img
                    src={require(`../../../assets/Services/${service.imgicon}.webp`)}
                    alt="logo"
                    className="service-cardIcon mb-4 mt-2"
                  />
                  <h5 className="information-title text-light mb-2 fw-normal">{service.title}</h5>
                  <p className="information-p text-light text-justify">
                    {service.info}
                  </p>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </section>
    </div>
  );
}

export default Services;
