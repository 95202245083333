import React, { useState } from "react";
import "./ServicesPages.css";
import NavBar from "../../components/NavBar/Navbar";
import ServicesHero from "../../assets/Hero/services.webp";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AboutForm from "../../components/AboutForm/AboutForm";
import Newsletter from "../../components/Newsletter/Newsletter";
import Footer from "../../components/Footer/Footer";
import Cookie from "../../components/Cookie/Cookie";
import serviceJson from "./Service.json"

function ServicesPages() {
  // State to track which service's content is shown
  const [expanded, setExpanded] = useState(null);

  // Handle toggle for each "Learn More" button
  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index); // Toggle on/off for the same card
  };

  const [service] = useState(serviceJson);

  return (
    <div>
      <Cookie />
      <NavBar />
      <section className="service-body">
        <img src={ServicesHero} alt="bg" className="service-image" />
      </section>

      <section
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ background: "#EFF7FF" }}
      >
        <div className="d-flex justify-content-center align-items-center text-dark mt-5">
          <h1 className="fs-1">
            Our <span style={{ color: "#0167DB" }}>Services</span>
          </h1>
        </div>
        <div className="container align-items-center">

          <Row className="gx-0 g-5 mt-1 mb-5 justify-content-center align-items-center">
            {service.map((service, i) => (
              <Col
                key={i}
                xs={12}
                sm={12}
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <Card
                  className="cardServices text-dark p-3 mx-sm-auto mx-md-auto"
                  style={{ width: "100%" }}
                >
                  <Card.Body className="d-flex flex-row justify-content-start align-items-center">
                    <div>
                      <img
                        src={require(`../../assets/ServicesPages/${service.imgicon}.webp`)}
                        alt="logo"
                        className="cardSPage-icon"
                      />
                    </div>
                    <div className="text-box">
                      <h5 className="service-title text-dark mb-4">{service.title}</h5>

                      {expanded !== i && (
                        <>
                          <p className="para-more text-dark">{service.info}</p>
                          <button className="button-md button-sm" onClick={() => handleToggle(i)}>
                            Learn More
                          </button>
                        </>
                      )}


                      {expanded === i && (
                        <p
                          className="para-more text-dark"
                        >
                          {service.more}
                        </p>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </section>

      <AboutForm />
      <Newsletter />
      <Footer />
    </div>
  );
}
export default ServicesPages;
