import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Partnerships.css";
import partnershipJson from "./Partnership.json"

function Services() {
  const [partner] = useState(partnershipJson);
  return (  
    <div>
      <section
        className="d-flex flex-column align-items-center"
      >
        <h3 className="partners-title mt-4">Our <span style={{ color: "#0167DB" }}>Valued Partners</span></h3>
        <Row
          className="g-3 d-flex justify-content-center align-items-center my-2 mb-4"
        >
          {partner.map((partner, i) => (
            <Col
              key={i}
              xs={2} //cards per row on extra small screens
              md={2} //cards per row on medium screens
              lg={1} //cards per row on large screens
              className="d-flex justify-content-center align-items-center mx-4"
            >
                <img src={require(`../../../assets/Partners/${partner.logo}.webp`)} alt="Logo" className="w-100 text-dark"/>
            </Col>
          ))}
        </Row>
      </section>
    </div>
  );
}

export default Services;
