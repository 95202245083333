import "bootstrap/dist/css/bootstrap.min.css";
import "./AboutForm.css"
import contact from "../../assets/contact.png";

function AboutForm() {
  return (
    <div className="about-form">
      <section className="about-section d-flex flex-column">
        <h6 className="about-title mb-3 text-light mt-5">Get in Touch</h6>
        <p className="about-p text-light mb-4">
          With decades of combined global business, IT, and cybersecurity
          experience, our team of business, IT, and cybersecurity <br />
          professionals are dedicated to safeguarding clients intellectual
          property and privacy. We provide cutting-edge solutions to ensure
          <br />
          Client Organization stays competitive and ahead of the evolving cyber
          threat landscape.
        </p>
      </section>
      <div className="second-part ">
        <div className="img-box w-100">
          <img src={contact} alt="Form" className="w-50" />
        </div>
        <div className="input-box w-100">
          <input type="text" className="fullName" placeholder="Full Name" />
          <input
            type="email"
            className="businessMail"
            placeholder="Business Email"
          />
          <input
            type="text"
            className="orgName"
            placeholder="Organization Name"
          />
          <textarea className="query" placeholder="Query" />
          <button >SUBMIT</button>
        </div>
      </div>
    </div>
  );
}

export default AboutForm;
