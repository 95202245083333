import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Educational.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import educationJson from "./Educational.json"

function Educational() {
  // State to track which educational's content is shown
  const [expanded, setExpanded] = useState(null);

  // Handle toggle for each "Learn More" button
  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index); // Toggle on/off for the same card
  };

  const [educational] = useState(educationJson);

  return (
    <div>
      <section
        className="d-flex flex-column align-items-center justify-content-center" // Add my-4 for vertical margin
      >
        {/* CARDS */}
        <div className="container-fluid">
          <Row className="g-5 d-flex justify-content-center align-items-center">
            {educational.map((educational, i) => (
              <Col key={i} xs={12} sm={10} md={10} className="d-flex justify-content-center align-content-center">
                <Card className="educCards text-dark p-3 mx-sm-auto mx-md-auto">
                  <Card.Body className="d-flex flex-column flex-sm-row justify-content-start align-items-center">
                    <div>
                      <img
                        src={require(`../../../assets/Partners/${educational.imgicon}.webp`)}
                        alt="logo"
                        className="cardEduc-icon"
                      />
                    </div>
                    <div className="textEduc-box">
                      <h5 className="educ-title text-dark mb-3 fw-bold">
                        {educational.title}
                      </h5>
                      {/* Conditionally render the info paragraph and button */}
                      {expanded !== i && (
                        <>
                          <ul className="educ-p text-dark">
                            {educational.info.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                          <button className="button-sm" onClick={() => handleToggle(i)}>
                            Learn More
                          </button>
                        </>
                      )}

                      {/* Show additional content if expanded */}
                      {expanded === i && (
                        <ul className="educ-p text-dark">
                        {educational.more.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>

      </section>
    </div>
  );
}

export default Educational;
