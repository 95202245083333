import React, { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Navbar from "./components/NavBar/Navbar";
import Services from "./components/Cards/Services/Services";
import Partnerships from "./components/Cards/Partnerships/Partnerships";
import Newsletter from "./components/Newsletter/Newsletter";
import logo from "./assets/logo-hero.png";
import AboutForm from "./components/AboutForm/AboutForm";
import Footer from "./components/Footer/Footer";
import Cookie from "./components/Cookie/Cookie"

function App() {
  // Create a ref to the AboutForm section
  const aboutFormRef = useRef(null);

  // Function to scroll to the AboutForm
  const handleContactUsClick = () => {
    aboutFormRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="App">
      <Cookie />
      <Navbar /> {/*Navigation Bar*/}
      <section className="main-body">
        
        <div className="hero-margin text-start hero-lg">
          <div>
            <img src={logo} alt="hero-logo" className="hero-logo"></img>
          </div>
          <div className="hero-sub">
            <h5 className="tagline fw-normal pt-2">
              "Where <span className="fw-bold">Innovation, Technology,</span> and{" "}
              <span className="fw-bold">Talent</span> are <br /> fused to exponentially
              scale the Cyber challenges."
            </h5>
            <button
              className="connect-button"
              href=""
              onClick={handleContactUsClick}
            >
              Contact Us
            </button>
          </div>
        </div>

        <div className="hero-margin text-center hero-md">
          <div>
            <img src={logo} alt="hero-logo" className="hero-logo"></img>
          </div>
          <div className="hero-sub">
            <h5 className="tagline fw-normal pt-2">
              "Where <span className="fw-bold">Innovation, Technology,</span> and{" "}
              <span className="fw-bold">Talent</span> are <br /> fused to exponentially
              scale the Cyber challenges."
            </h5>
            <button
              className="connect-button"
              href=""
              onClick={handleContactUsClick}
            >
              Contact Us
            </button>
          </div>
        </div>

        <div className="hero-margin text-center hero-sm">
          <div>
            <img src={logo} alt="hero-logo" className="hero-logo"></img>
          </div>
          <div className="hero-sub">
            <h5 className="tagline fw-normal pt-2">
              "Where <span className="fw-bold">Innovation, Technology,</span> and{" "}
              <span className="fw-bold">Talent</span> are <br /> fused to exponentially
              scale the Cyber challenges."
            </h5>
            <button
              className="connect-button"
              href=""
              onClick={handleContactUsClick}
            >
              Contact Us
            </button>
          </div>
        </div>
        
      </section>
      
      <section className="info-section text-center d-flex flex-column">
        <h6 className="fw-bold mb-lg-5 mb-md-3 fs-4 fs-md-5 fs-sm-6 ">
          Your Trusted Security Operations Partner in a World of AI and Digital
          Technology
        </h6>
        <p className="info-p fs-6 fs-md-5 fs-sm-5 ">
          FusionNode understands that in today's interconnected world,
          cybersecurity is not just a necessity - it is a cornerstone of
          success. As your trusted Managed Security Service Provider (MSSP), we
          are here to be your vigilant guardian, standing between your business
          and the ever-evolving threats of the digital landscape.
        </p>
      </section>
      <Partnerships /> 
      <Services /> 
      <div ref={aboutFormRef}>
        <AboutForm />
      </div>
      <Newsletter />
      <Footer />
    </div>
  );
}

export default App;
