import "bootstrap/dist/css/bootstrap.min.css";
import "./Footer.css";
import logo from "../../assets/logo2.png"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEnvelope, faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebook,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <footer className="footer">
      <section className="text-center d-flex flex-row footer-lg">
        <div className="left-side">
          <img src={logo} alt="Logo" className="img-logo"></img>
          <div className="link-box">
            <Link to="/">HOME</Link>
            <Link to="/services">SOLUTIONS</Link>
            <Link to="/partnered">PARTNERS</Link>
            <Link to="/howItWorks">HOW IT WORKS</Link>
            <Link to="/about">ABOUT</Link>
            <p>
              <FontAwesomeIcon icon={faLocationDot} size="lg" className="me-1" />
              4th Floor STEER Hub Bldg., BatStateU KIST Park Alangilan, <br />{" "}
              Batangas City 4200 Batangas, Philippines​​
            </p>
            <p>
              <FontAwesomeIcon icon={faLocationDot} size="lg" className="me-1" />
              7600 Dublin Blvd Ste 230 Dublin, CA 94568 
            </p>
          </div>
        </div>
        <div className="right-side">
          <h6>CONTACT US</h6> 
          <a href="mailto:info@fusionnode.ai" className="text-decoration-none text-light">
            <p className="email-p">
              <FontAwesomeIcon icon={faEnvelope} size="lg" className="me-1" />
              info@fusionnode.ai
            </p>
          </a>
          <div className="d-flex flex-row mt-3">
            <a
              href="https://www.facebook.com/profile.php?id=61560821002326"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faFacebook} size="xl" className="me-5" />
            </a>
            <a
              href="https://www.instagram.com/fusionnode/?utm_source=ig_web_button_share_sheet"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faInstagram} size="xl" className="me-5" />
            </a>
            <a
              href="https://www.linkedin.com/company/fusionnode/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faLinkedin} size="xl" className="me-5" />
            </a>
          </div>
        </div>
      </section>

      <section className="footer-md">
        <div className="left-side">
            <img src={logo} alt="Logo" className="img-md-logo"></img>
            <div className="link-box">
              <Link to="/">HOME</Link>
              <Link to="/services">SOLUTIONS</Link>
              <Link to="/partnered">PARTNERS</Link>
              <Link to="/howItWorks">HOW IT WORKS</Link>
              <Link to="/about">ABOUT</Link>
              <a href="mailto:info@fusionnode.ai" className="text-decoration-none text-light">
                <p className="email-p">
                  <FontAwesomeIcon icon={faEnvelope} size="lg" className="me-1" />
                  info@fusionnode.ai
                </p>
              </a>
              <p>
              <FontAwesomeIcon icon={faLocationDot} size="lg" className="me-1" />
                4th Floor STEER Hub Bldg., BatStateU KIST Park Alangilan, <br />{" "}
                Batangas City 4200 Batangas, Philippines​​
              </p>
              <p>
                <FontAwesomeIcon icon={faLocationDot} size="lg" className="me-1" />
                7600 Dublin Blvd Ste 230 Dublin, CA 94568 
              </p>
              <p>Copyright © 2024 FusionNode - All Rights Reserved.</p>
              <a
              href="https://www.facebook.com/profile.php?id=61560821002326"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faFacebook} size="xl" className="me-5" />
            </a>
            <a
              href="https://www.instagram.com/fusionnode/?utm_source=ig_web_button_share_sheet"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faInstagram} size="xl" className="me-5" />
            </a>
            <a
              href="https://www.linkedin.com/company/fusionnode/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faLinkedin} size="xl"/>
            </a>
            </div>
          </div>
      </section>

      <section className="footer-sm">
        <div className="text-center justify-content-center">
          <img src={logo} alt="Logo" className="img-sm-footer"></img>
        </div>
        <div className="row g-0 link-box text-center">
            <Link to="/">HOME</Link>
            <Link to="/services">SOLUTIONS</Link>
            <Link to="/partners">PARTNERS</Link>
            <Link to="/howItWorks">HOW IT WORKS</Link>
            <Link to="/about">ABOUT</Link>
          </div>
      </section>
      <div className="bottom-part d-flex footer-lg">
        <p>Copyright © 2024 FusionNode - All Rights Reserved.</p>
      </div>
      <div className="bottom-part footer-sm justify-content-center m-auto">
        <p style={{fontSize: "13px"}}>
          <FontAwesomeIcon icon={faLocationDot} size="lg" className="me-1" />
            4th Floor STEER Hub Bldg., BatStateU KIST Park Alangilan, <br />{" "}
            Batangas City 4200 Batangas, Philippines​​
        </p>
        <p style={{fontSize: "13px"}}>
          <FontAwesomeIcon icon={faLocationDot} size="lg" className="me-1" />
          7600 Dublin Blvd Ste 230 Dublin, CA 94568 
        </p>
        <p>Copyright © 2024 FusionNode - All Rights Reserved.</p>
      <div>
      <a
              href="https://www.facebook.com/profile.php?id=61560821002326"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faFacebook} size="xl" className="me-5" />
            </a>
            <a
              href="https://www.instagram.com/fusionnode/?utm_source=ig_web_button_share_sheet"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faInstagram} size="xl" className="me-5" />
            </a>
            <a
              href="https://www.linkedin.com/company/fusionnode/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FontAwesomeIcon icon={faLinkedin} size="xl"/>
            </a>
      </div>
        
      </div>
    </footer>
  );
}

export default Footer;
