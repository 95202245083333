import React, { useState } from "react";
import "./About.css";
import NavBar from "../../components/NavBar/Navbar";
import AboutHero from "../../assets/Hero/about.webp";
import AboutForm from "../../components/AboutForm/AboutForm";
import Newsletter from "../../components/Newsletter/Newsletter";
import Cookie from "../../components/Cookie/Cookie";
import Footer from "../../components/Footer/Footer";
import { Card, Row, Col } from "react-bootstrap";
import Jerry from "../../assets/Team/jerry.webp";
import aboutJson from "./About.json";


function About() {
  const [usTeam] = useState(aboutJson.usTeamGroup);
  const [phTeam] = useState(aboutJson.phTeamGroup);
  const [boardAdvisors] = useState(aboutJson.boardAdvisorGroup);

  return (
    <div>
      <Cookie />
      <NavBar />
      <section className="about-body">
        <img src={AboutHero} alt="bg" className="about-image" />
      </section>
      <section className="info-sec">
        <p className="fs-md-5 fs-sm-5 ms-5 me-5">
          FusionNode understands that in today's interconnected world,
          cybersecurity is not just a necessity—it is a cornerstone of success.
          As your trusted Security Partner, we are here to be your vigilant
          guardian standing between your business and the ever-evolving threats
          of the digital landscape.​
        </p>
      </section>
      <div>
        <section className="ceo-section d-flex flex-column justify-content-center align-items-center">
          <h1 className="text-light fw-bold mt-5">
            MEET <span style={{ color: "#92C9FF" }}>THE TEAM</span>
          </h1>
          <Card className="team-card mt-5 mb-0">
            <img src={Jerry} alt="CEO" className="team-img" />
          </Card>
          <div className="d-flex flex-column justify-content-center align-items-center my-3 g-0 text-light">
            <h6 className="mb-0 fw-normal text-center">JERRY STO. TOMAS</h6>
            <p className="mt-0 text-center">CEO/Founder</p>
          </div>
        </section>

        <section className="ff-positions d-flex flex-column justify-content-center align-items-center">
          <h1 className="text-light fw-bold mb-5 my-5">
            US TEAM <span style={{ color: "#92C9FF" }}>MEMBERS</span>
          </h1>
          <Row className="about-row g-5 mb-5 d-flex justify-content-center">
            {usTeam.map((usMember, i) => (
              <Col
                key={i}
                xs={12} // cards per row on extra small screens
                md={6} // cards per row on medium screens
                lg={2} // cards per row on large screens
                className="d-flex justify-content-center my-5 mx-3"
              >
                <Card className="team-card d-flex align-items-center my-4">
                    <img
                      src={require(`../../assets/Team/${usMember.memberImg}.webp`)}
                      alt="logo"
                      className="team-img"
                    />
                    <div className="d-flex flex-column justify-content-center align-items-center g-0 text-light">
                      <h6 className="text-light mt-3 mb-0 fw-normal text-center">
                        {usMember.memberName}
                      </h6>
                      <p className="text-light mt-0 text-center">{usMember.memberTitle}</p>
                    </div>
                </Card>
              </Col>
            ))}
          </Row>
        </section>
      </div>

      {/* PH TEAM */}
      <div>
        <section className="ff-positions d-flex flex-column justify-content-center align-items-center">
          <h1 className="text-light fw-bold mb-5 my-5">
            PH TEAM <span style={{ color: "#92C9FF" }}>MEMBERS</span>
          </h1>
          <Row className="about-row g-5 mb-5 d-flex justify-content-center">
            {phTeam.map((phMember, i) => (
              <Col
                key={i}
                xs={12} // cards per row on extra small screens
                md={6} // cards per row on medium screens
                lg={2} // cards per row on large screens
                className="d-flex justify-content-center my-5 mx-3"
              >
                <Card className="team-card d-flex align-items-center my-4">
                    <img
                      src={require(`../../assets/Team/${phMember.memberImg}.webp`)}
                      alt="logo"
                      className="team-img"
                    />
                    <div className="d-flex flex-column justify-content-center align-items-center g-0 text-light">
                      <h6 className="text-light mt-3 mb-0 fw-normal text-center">
                        {phMember.memberName}
                      </h6>
                      <p className="text-light mt-0 text-center">{phMember.memberTitle}</p>
                    </div>
                </Card>
              </Col>
            ))}
          </Row>
        </section>
      </div>

      {/* BoardAdvisors */}
      <div>
        <section className="ff-positions d-flex flex-column justify-content-center align-items-center">
          <h1 className="text-light fw-bold mb-5 my-5">
            BOARD <span style={{ color: "#92C9FF" }}>ADVISORS</span>
          </h1>
          <Row className="about-row g-5 mb-5 d-flex justify-content-center">
            {boardAdvisors.map((baMember, i) => (
              <Col
                key={i}
                xs={12} // cards per row on extra small screens
                md={6} // cards per row on medium screens
                lg={3} // cards per row on large screens
                className="d-flex justify-content-center my-5 mx-3"
              >
                <Card className="team-card d-flex align-items-center my-4">
                    <img
                      src={require(`../../assets/Team/${baMember.memberImg}.webp`)}
                      alt="logo"
                      className="team-img"
                    />
                    <div className="d-flex flex-column justify-content-center align-items-center g-0 text-light">
                      <h6 className="text-light mt-3 mb-0 fw-normal text-center">
                        {baMember.memberName}
                      </h6>
                      <p className="text-light mt-0 text-center">{baMember.memberTitle}</p>
                    </div>
                </Card>
              </Col>
            ))}
          </Row>
        </section>
      </div>
      <AboutForm/>
      <Newsletter />
      <Footer />
    </div>
  );
}

export default About;