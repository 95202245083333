import React, {useState, useEffect} from 'react';
import cookieLogo from "../../assets/fncookie.png";
import "./Cookie.css";
import { Link } from 'react-router-dom';
function Cookie() {
   
    const [showModal, setShowModal] = useState(false);

    // Check if consent is already given
    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
        setShowModal(true);
        }
    }, []);

      // Function to handle consent
    const acceptConsent = () => {
        localStorage.setItem('cookieConsent', 'true');
        loadGoogleAnalytics();
        setShowModal(false);
    };
    const rejectConsent = () => {
        setShowModal(false);
    };

    const loadGoogleAnalytics = () => {
        if (!window.gtag) {
          const script = document.createElement('script');
          script.src = `https://www.googletagmanager.com/gtag/js?id=G-3DRN41ZYKW`;
          script.async = true;
          document.head.appendChild(script);
    
          script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() { window.dataLayer.push(arguments); }
            window.gtag = gtag;
            gtag('js', new Date());
            gtag('config', 'G-3DRN41ZYKW'); // Replace with your Google Analytics ID
          };
        }
    };


    return (
        <>
          {showModal && (
            <div className="main rounded-3 ">
            <div className="my-5">
            <section className="text-center">
                <img src={cookieLogo} alt="Logo" className="w-25 " />
            </section>
            <section className="mx-5 my-5">
                <p className="text-light">
                We use cookies to enhance your browsing experience and analyze site
                traffic. By clicking "Accept," you agree to our use of cookies in
                accordance with our{" "} 
                <span>
                    <Link>Privacy Policy</Link>
                </span>
                . If you prefer not to accept cookies you can adjust your
                preferences. Please note that declining cookies may affect your
                experience on the site.
                </p>
            </section>
            <section className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
                <button className="accept-btn me-5" onClick={acceptConsent}>Accept All Cookies</button>
                <button className="reject-btn" onClick={rejectConsent}>Reject All Cookies</button>
            </section>
            </div>
            </div>
          )}
        </>
      );

}
export default Cookie;