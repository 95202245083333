import React from "react";
import "./Partners.css";
import NavBar from "../../components/NavBar/Navbar";
import OurPartners from "../../assets/Hero/partners.webp";
import Educational from "../../components/Cards/Educational/Educational";
import Partnered from "../../components/Cards/PartneredServices/Partnered";
import AboutForm from "../../components/AboutForm/AboutForm";
import Footer from "../../components/Footer/Footer";
import Newsletter from "../../components/Newsletter/Newsletter"
import Cookie from "../../components/Cookie/Cookie";

const About = () => (
  <div>
    <Cookie />
    <NavBar />
    <section className="partners-body">
      <img src={OurPartners} alt="bg" className="partners-image" />
    </section>
    <section className="sections">
      <h1 className="text-light fw-bold">
        <span style={{ color: "#0167DB" }}>Educational</span> Partners
      </h1>
    </section>
    <Educational />
    <section className="sections">
      <h1 className="text-light fw-bold">
        <span style={{ color: "#0167DB" }}>Partnered</span> Services
      </h1>
    </section>
    <Partnered />
    <AboutForm />
    <Newsletter />
    <Footer />
  </div>
);

export default About;
