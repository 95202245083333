import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Partnered.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import partnerJson from './Partnered.json'

function Partnered() {
  // State to track which partnered's content is shown
  const [expanded, setExpanded] = useState(null);

  // Handle toggle for each "Learn More" button
  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index); // Toggle on/off for the same card
  };

  const [partnered] = useState(partnerJson);

  return (
    <div>
      <section
        className="d-flex flex-column align-items-center justify-content-center" 
      >
        <div className="container-fluid">
          <Row className="g-5 d-flex justify-content-center align-items-center">
            {partnered.map((partnered, i) => (
              <Col
                key={i}
                xs={12}
                sm={10}
                md={10}
                className="d-flex justify-content-center align-items-center"
                
              >
                <Card className="cardPartnered text-dark p-3 mx-sm-auto mx-md-auto">
                  <Card.Body className="d-flex flex-column flex-sm-row justify-content-start align-items-center">
                    <div>
                      <img
                        src={require(`../../../assets/Partners/${partnered.imgicon}.webp`)}
                        alt="logo"
                        className="cardPart-icon"
                      />
                    </div>
                    <div className="textPart-box">
                      <h5 className="partner-title text-dark mb-3 fw-bold">
                        {partnered.title}
                      </h5>
                      {/* Conditionally render the info paragraph and button */}
                      {expanded !== i && (
                        <>
                          <ul className="educ-p text-dark">
                            {partnered.info.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                          <button className="button-sm" onClick={() => handleToggle(i)}>
                            Learn More
                          </button>
                        </>
                      )}

                      {/* Show additional content if expanded */}
                      {expanded === i && (
                        <ul className="educ-p text-dark">
                        {partnered.more.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </div>
  );
}

export default Partnered;
