import "../../App.css";
import "./Newsletter.css"

function Newsletter() {
  return (
    <div className="d-flex flex-row justify-content-center align-items-center newsletter">
      <div>
        <h2 className="newsletter-text">Get updates and Newsletter from FusionNode!</h2>
      </div>
      <div>
        <button className="newsletter-button">Subscribe</button>
      </div>
    </div>
  );
}

export default Newsletter;
